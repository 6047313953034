.branches {
    height: 40vh;

    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.16);
    backdrop-filter: blur(19.5px);
    -webkit-backdrop-filter: blur(19.5px);
    border-top-left-radius: 26px;
    border-top-right-radius: 26px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}