@tailwind base;
@tailwind components;
@tailwind utilities;





body {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.firebase-emulator-warning {
  display: none;
}

.radial-progress:before {
  
  background: radial-gradient(farthest-side, #2699FB 98%, #0000) top/var(--thickness) var(--thickness) no-repeat, conic-gradient(#2699FB calc(var(--value) * 1%), #0000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness)), #000 calc(100% - var(--thickness)));
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness)), #000 calc(100% - var(--thickness)));
}
.radial-progress:after {
  background-color: #2699FB;
  transform: rotate(calc(var(--value) * 3.6deg - 90deg)) translate(calc(var(--size) / 2 - 54%));
}


.blink {
  animation: blinker .75s linear infinite;  
}
@keyframes blinker {
  50% {
      opacity: 0;
  }
}




.c_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: none;
}

.c_overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.c_button-container {
  margin-top: 20px;
}

.c_btn-confirm {
  background-color: #2699FB;
  color: white;
  border: none;
  margin: 0 10px;
}
.c_btn-confirm:hover {
  background-color: #2699FB;
  color: white;  
}

.c_btn-cancel {
  background-color: transparent;
  color: #696969;
  border: none;
  margin: 0 10px;
}
.c_btn-cancel:hover {
  background-color: transparent;
  color: #696969;
}






.rmdp-wrapper {
  box-shadow: none !important;  
  border: 1px solid #B3BEC4;
  border-radius: 0.375rem !important;
}
.rmdp-time-picker {
  padding: 12px 0 !important;
}
.rmdp-border-top {
  border-top: 1px solid #B3BEC4 !important;
}
.rmdp-calendar {
  width: 100%; 
  padding: 0 20px !important; 
}
.rmdp-header {
  margin-top: 30px !important;
}
.rmdp-day-picker > div {
  width: 100%;
  margin: 20px 0;
}







/* :where(html) {
  --darkmode: 0;
  container-name: root;
  container-type: normal;
}

body {
  --background-color: #FFF;
  --text-color: #222;
  background-color: var(--background-color);
  color: var(--text-color);
  color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
  html { --darkmode: 1; background-color: hsl(228, 5%, 15%) !important; }
}
@media (prefers-color-scheme: light) {
  html { --darkmode: 0; background-color: hsl(0, 0%, 100%) !important; }
}

html:has(#color-scheme-light:checked) { --darkmode: 0; }
html:has(#color-scheme-dark:checked) { --darkmode: 1; }

@container root style(--darkmode: 1) {

  

  body {
    --background-color: hsl(228, 5%, 15%);
    --text-color: hsl(228, 5%, 80%);
  }
  
  .tab, .text-neutral, span, strong {
    color: hsl(228, 5%, 80%) !important;
  }  
}

.toggle-group {
  border: 2px solid #CCC;
  border-radius: 24px;
  inline-size: fit-content;
  padding: 3px;
  margin-bottom: 20px;
}
.toggle-group label {
  border-radius: 21px;
  cursor: pointer;
  display: inline-flex;
  padding: 0.5em 1em;
  text-align: center;
  user-select: none;
}
.toggle-group label:has(input:checked) {
  background-color: #CCC;
  color: #333;
}

[data-sr] {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
} */