

.rtl .peer:focus~.peer-focus\:before\:border-l-2::before {
  border-left-width: 0px;
  border-right-width: 2px;
}
.rtl .before\:rounded-tl-md::before {  
  border-top-left-radius: 0;
  border-top-right-radius: 0.375rem;
}
.rtl .before\:mr-1::before {  
  margin-right: 0;
  margin-left: 0.25rem;
}


.rtl .peer:focus~.peer-focus\:after\:border-r-2::after {  
  border-right-width: 0;
  border-left-width: 2px;
}
.rtl .after\:rounded-tr-md::after {  
  border-top-right-radius: 0;
  border-top-left-radius: 0.375rem;
}
.rtl .after\:ml-1::after {  
  margin-left: 0;
  margin-right: 0.25rem;
}


.rtl .right-3 {
  right: auto;
  left: 0.75rem;
}


.rtl .\!pr-9 {
  padding-left: 2.25rem !important;
  padding-right: 0.75rem !important;
}


.steps .step:after {
  right: -.75rem;
}


:where(.stats) > :not([hidden]) ~ :not([hidden]) {  
  border-left-width: calc(1px * var(--tw-divide-x-reverse));
  border-right-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));  
}

.rtl .rmdp-calendar {
  direction: rtl;
}
.rtl .rmdp-time-picker {
  direction: rtl !important;
}